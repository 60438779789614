import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

export default function standards() {
  return (
    <Box mt={['1.5em', '1.5em', '6em']} mb="100px">
      <Helmet>
        <title>منهجية ميسك</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        المنهجية
      </Heading>
      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          يؤمن العاملون في المركز بفاعلية ومردود العمل الجماعي (Action
          collective) على عملية إنتاج المعرفة، من خلال المشاركة والتعاون بين
          كلٍّ من الباحث والمحرر، والمترجم، والمساعد، والمتدرب، والفريق
          الإعلامي، لتطوير البحث، بدءاً من تحديد موضوع الدراسة أو البحث ووصولاً
          إلى النسخة النهائية.
        </Text>
        <Text fontSize="2xl" m="4">
          سنتبع في (ميسك-MISC) هذا النهج، عبر اختيار ثيمة البحث بعناية، ثم توزيع
          المهام، وإشراك المختصين والفاعلين في المشروع الذي يتم العمل عليه، ثم
          جمع المعلومات والمواد والتعمق فيها، ثم مرحلة الكتابة.
        </Text>
        <Text fontSize="2xl" m="4">
          كما أننا نؤمن، في (ميسك-MISC)، أن النشر ليس غاية نهائية، بل وسيلة
          للتفاعل مع القارئ. وبناء على ذلك سيتم تخصيص وقت ومساحة لورشات العمل
          والندوات التي تتناول مُخرجات المركز ودراساته ومنشوراته، ومنشورات
          الآخرين ذات الصلة بمجال عمله.
        </Text>
      </Box>
    </Box>
  );
}
